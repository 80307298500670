var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("WidgetFrame", {
    attrs: { contentClass: "overflow-unset" },
    on: { onResize: _vm.onResize },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _vm._v(" " + _vm._s(_vm.$t("permission.department_staff")) + " "),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            !_vm.canView("DEPARTMENT", ["STAFF"]) || !_vm.canView("DEPARTMENT")
              ? [
                  _c("div", { staticClass: "center-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "entity_selector.error.insufficient_permission_to_show_data"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              : !_vm.loaded
              ? [
                  _c("div", { staticClass: "status-message" }, [
                    _vm._v("Loading..."),
                  ]),
                ]
              : _vm.noDepartments
              ? [
                  _c("div", { staticClass: "status-message" }, [
                    _vm._v("No departments"),
                  ]),
                ]
              : [
                  _c(
                    "div",
                    {
                      staticClass:
                        "input-group home-department-staff-input-group",
                    },
                    [
                      _c("div", { staticClass: "input-group-prepend" }, [
                        _c(
                          "label",
                          {
                            staticClass: "input-group-text",
                            attrs: { for: "dateEnd" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("dashboard.widgets.date_range")) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("multiselect", {
                        staticClass:
                          "custom-dropdown-options enable-option-icon",
                        attrs: {
                          "max-height": 300,
                          options: _vm.optionTypes.map((i) => i.key),
                          "custom-label": _vm.getTypeOptionLabel,
                          placeholder: "",
                          searchable: false,
                          "allow-empty": false,
                          showLabels: false,
                          disabled: !_vm.canView("STAFF", [
                            "startDate",
                            "endDate",
                          ]),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "option",
                            fn: function (props) {
                              return [
                                _vm.type == props.option
                                  ? _c("font-awesome-icon", {
                                      staticClass: "selected-option-icon",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                                _c("span", { staticClass: "option__title" }, [
                                  _vm._v(
                                    _vm._s(_vm.getTypeOptionLabel(props.option))
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "chart-holder" },
                    [
                      _vm.loaded
                        ? _c("ag-charts-vue", {
                            attrs: { options: _vm.options },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.sourceData.length > _vm.pageSize
                    ? _c("Pager", {
                        attrs: {
                          sourceData: _vm.sourceData,
                          pageSize: _vm.pageSize,
                          small: _vm.width < 450,
                          reset: _vm.reset,
                        },
                        on: {
                          restored: _vm.onRestored,
                          paged: _vm.onPageChanged,
                        },
                      })
                    : _vm._e(),
                ],
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }