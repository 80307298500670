var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.allowManage && _vm.datesMode !== "current" && !_vm.isWidget
        ? _c(
            "PriorityNavigation",
            {
              staticClass:
                "d-flex align-items-center dataview-action-bar time-toolbar",
            },
            [
              _c("li", [
                !_vm.epoch && _vm.startDate && _vm.endDate
                  ? _c(
                      "span",
                      { staticClass: "d-flex ml-2 mr-2" },
                      [
                        _c("span", { staticClass: "mt-1 mr-1" }, [
                          _vm._v(_vm._s(_vm.$t("dataview.chart.data"))),
                        ]),
                        _c("b-form-select", {
                          staticClass: "mw-150 minw-170",
                          attrs: {
                            id: "fields",
                            options: _vm.numberFieldOptions,
                            size: "sm",
                          },
                          on: { change: _vm.fieldSelected },
                          model: {
                            value: _vm.timeseries_field,
                            callback: function ($$v) {
                              _vm.timeseries_field = $$v
                            },
                            expression: "timeseries_field",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              !_vm.epoch
                ? _c("li", [
                    _vm.editPermission
                      ? _c(
                          "span",
                          {
                            staticClass: "action-v-divider",
                            attrs: { readonly: "" },
                          },
                          [_vm._v("|")]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _c("li", [
                _vm.epoch && _vm.startDate && _vm.endDate
                  ? _c(
                      "span",
                      { staticClass: "d-flex align-items-center ml-2 mr-2" },
                      [
                        _c("label", { staticClass: "mr-1" }, [
                          _vm._v(_vm._s(_vm.spanPrefix)),
                        ]),
                        _c(
                          "b-btn",
                          {
                            attrs: {
                              disabled:
                                _vm.options === null || _vm.disableEpochLeft,
                              id: "BTN_LEFT_EPOCH_CHART",
                            },
                            on: { click: _vm.leftEpoch },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "chevron-circle-left"] },
                            }),
                            _c("b-popover", {
                              attrs: {
                                target: "BTN_LEFT_EPOCH_CHART",
                                placement: "top",
                                boundary: "viewport",
                                triggers: "hover",
                                content: _vm.$t("dataview.previous"),
                              },
                            }),
                          ],
                          1
                        ),
                        _c("b-form-select", {
                          staticClass: "mw-150 minw-170",
                          attrs: {
                            id: "epochs",
                            options: _vm.epochOptions,
                            size: "sm",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.epochSelected()
                            },
                          },
                          model: {
                            value: _vm.epoch,
                            callback: function ($$v) {
                              _vm.epoch = $$v
                            },
                            expression: "epoch",
                          },
                        }),
                        _c(
                          "b-btn",
                          {
                            attrs: {
                              disabled:
                                _vm.options === null || _vm.disableEpochRight,
                              id: "BTN_RIGHT_EPOCH_CHART",
                            },
                            on: { click: _vm.rightEpoch },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "chevron-circle-right"] },
                            }),
                            _c("b-popover", {
                              attrs: {
                                target: "BTN_RIGHT_EPOCH_CHART",
                                placement: "top",
                                boundary: "viewport",
                                triggers: "hover",
                                content: _vm.$t("dataview.next"),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("li", [
                _c(
                  "span",
                  { staticClass: "d-flex align-items-center ml-2 mr-2" },
                  [
                    _c(
                      "label",
                      { staticClass: "mr-1", attrs: { for: "dates" } },
                      [_vm._v(_vm._s(_vm.$t("staff.dates")))]
                    ),
                    _c("b-form-select", {
                      staticClass: "mw-150 minw-170",
                      attrs: {
                        id: "dates",
                        options: _vm.dateOptions,
                        size: "sm",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.rangeSelected()
                        },
                      },
                      model: {
                        value: _vm.dates,
                        callback: function ($$v) {
                          _vm.dates = $$v
                        },
                        expression: "dates",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("li", [
                _c(
                  "span",
                  { staticClass: "d-flex align-items-center mr-1 date" },
                  [
                    _c(
                      "label",
                      { staticClass: "mr-1", attrs: { for: "startDate" } },
                      [_vm._v(_vm._s(_vm.$t("staff.from")))]
                    ),
                    _c("b-form-datepicker", {
                      staticClass: "d-flex",
                      attrs: {
                        id: "chartStartDate",
                        "today-button": "",
                        "reset-button": "",
                        "close-button": "",
                        "hide-header": "",
                        "label-today-button": _vm.$t("date.today"),
                        "label-reset-button": _vm.$t("date.reset"),
                        "label-close-button": _vm.$t("date.close"),
                        "today-button-variant": "primary",
                        "reset-button-variant": "danger",
                        "close-button-variant": "secondary",
                        size: "sm",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.daySelected()
                        },
                      },
                      model: {
                        value: _vm.startDate,
                        callback: function ($$v) {
                          _vm.startDate = $$v
                        },
                        expression: "startDate",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("li", [
                _c(
                  "span",
                  { staticClass: "d-flex align-items-center mr-1 date" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "mt-1 mr-1 align-self-baseline",
                        attrs: { for: "endDate" },
                      },
                      [_vm._v(_vm._s(_vm.$t("staff.to")))]
                    ),
                    _c("b-form-datepicker", {
                      staticClass: "d-flex",
                      attrs: {
                        id: "chartEndDate",
                        "today-button": "",
                        "reset-button": "",
                        "close-button": "",
                        "hide-header": "",
                        "label-today-button": _vm.$t("date.today"),
                        "label-reset-button": _vm.$t("date.reset"),
                        "label-close-button": _vm.$t("date.close"),
                        "today-button-variant": "primary",
                        "reset-button-variant": "danger",
                        "close-button-variant": "secondary",
                        size: "sm",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.daySelected()
                        },
                      },
                      model: {
                        value: _vm.endDate,
                        callback: function ($$v) {
                          _vm.endDate = $$v
                        },
                        expression: "endDate",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("li", [
                _c(
                  "span",
                  { staticClass: "d-flex mr-1" },
                  [
                    _c(
                      "label",
                      { staticClass: "mt-1 mr-1", attrs: { for: "timescale" } },
                      [_vm._v(_vm._s(_vm.$t("staff.timescale")))]
                    ),
                    _c("b-form-select", {
                      staticClass: "mw-150",
                      attrs: {
                        id: "timescale",
                        options: _vm.spanOptions,
                        size: "sm",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.updateSpan()
                        },
                      },
                      model: {
                        value: _vm.span,
                        callback: function ($$v) {
                          _vm.span = $$v
                        },
                        expression: "span",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("li", [
                _c(
                  "span",
                  { attrs: { id: "BTN_USE_EPOCH_chart" } },
                  [
                    _c(
                      "b-btn",
                      {
                        style: _vm.useEpoch ? "color: #E77615" : "",
                        on: { click: _vm.toggleEpoch },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "history"] },
                        }),
                      ],
                      1
                    ),
                    _c("b-popover", {
                      attrs: {
                        target: "BTN_USE_EPOCH_chart",
                        placement: "top",
                        boundary: "viewport",
                        triggers: "hover",
                        content: _vm.useEpoch
                          ? _vm.$t("dataview.timemachine_on")
                          : _vm.$t("dataview.timemachine_off"),
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.allowManage && !_vm.isWidget
        ? _c("div", { staticClass: "grid-toolbar border" }, [
            _vm.canView(_vm.entityName) && _vm.editPermission
              ? _c(
                  "span",
                  [
                    _c("b-popover", {
                      attrs: {
                        target: "BTN_CHART_EDIT_chart",
                        placement: "top",
                        boundary: "viewport",
                        triggers: "hover",
                        content: _vm.$t("button.edit"),
                      },
                    }),
                    _c(
                      "b-btn",
                      {
                        attrs: { id: "BTN_CHART_EDIT_chart" },
                        on: {
                          click: function ($event) {
                            return _vm.editChart()
                          },
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "pen-to-square"] },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.canEdit(_vm.entityName) && _vm.editPermission
              ? _c(
                  "span",
                  [
                    _c("b-popover", {
                      attrs: {
                        target: "BTN_CHART_DELETE_chart",
                        placement: "top",
                        boundary: "viewport",
                        triggers: "hover",
                        content: _vm.$t("button.delete"),
                      },
                    }),
                    _c(
                      "b-btn",
                      {
                        attrs: { id: "BTN_CHART_DELETE_chart" },
                        on: {
                          click: function ($event) {
                            return _vm.removeChart()
                          },
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "trash-can"] },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.editPermission
              ? _c(
                  "span",
                  { staticClass: "action-v-divider", attrs: { readonly: "" } },
                  [_vm._v("|")]
                )
              : _vm._e(),
            _c(
              "span",
              [
                _c("b-popover", {
                  attrs: {
                    target: "BTN_CHART_EXPORT_DOCUMENT_chart",
                    placement: "top",
                    boundary: "viewport",
                    triggers: "hover",
                    content: _vm.$t("dataview.chart.export_chart"),
                  },
                }),
                _c(
                  "b-btn",
                  {
                    attrs: { id: "BTN_CHART_EXPORT_DOCUMENT_chart" },
                    on: {
                      click: function ($event) {
                        return _vm.chartExport("" + _vm.chart.name)
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["far", "inbox-out"] },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "span",
              {
                on: _vm._d({ mouseleave: _vm.onTimeModeLeave }, [
                  _vm.timeModeMouseEnterEvent,
                  _vm.onTimeModeOver,
                ]),
              },
              [
                _c(
                  "b-dropdown",
                  {
                    ref: "timeMode",
                    staticClass: "action-bar-dropdown",
                    attrs: {
                      id: "BTN_TIME_MODE",
                      "toggle-class": "text-decoration-none",
                      "no-caret": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "button-content",
                          fn: function () {
                            return [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "analytics"] },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3949019179
                    ),
                  },
                  [
                    _c(
                      "b-dropdown-group",
                      { attrs: { header: _vm.$t("dataview.date_settings") } },
                      [
                        _c(
                          "b-dropdown-item",
                          {
                            attrs: { href: "#" },
                            on: { click: _vm.onCurrent },
                          },
                          [
                            _c("span", { staticClass: "action-item-label" }, [
                              _vm._v(_vm._s(_vm.$t("dataview.current"))),
                            ]),
                            _vm.datesMode === "current"
                              ? _c("font-awesome-icon", {
                                  staticClass: "active-check",
                                  attrs: { icon: ["far", "check"] },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "b-dropdown-item",
                          {
                            attrs: { href: "#" },
                            on: { click: _vm.onActuals },
                          },
                          [
                            _c("span", { staticClass: "action-item-label" }, [
                              _vm._v(_vm._s(_vm.$t("dataview.actuals"))),
                            ]),
                            _vm.datesMode === "actuals" &&
                            _vm.epoch &&
                            _vm.startDate &&
                            _vm.endDate
                              ? _c("font-awesome-icon", {
                                  staticClass: "active-check",
                                  attrs: { icon: ["far", "check"] },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.canTimeseries
                          ? _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: { click: _vm.onTimeSeries },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("dataview.timeseries"))
                                    ),
                                  ]
                                ),
                                _vm.datesMode === "timeseries" &&
                                !_vm.epoch &&
                                _vm.startDate &&
                                _vm.endDate
                                  ? _c("font-awesome-icon", {
                                      staticClass: "active-check",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { class: _vm.isWidget ? _vm.widgetContainer : "chart-container" },
        [
          _vm.options !== null
            ? _c("ag-charts-vue", {
                ref: "agcharts",
                class: _vm.isWidget ? "widget-chart-style" : "chart-style",
                style: _vm.style,
                attrs: { options: _vm.options },
              })
            : _c(
                "div",
                { staticClass: "loading" },
                [
                  _c("b-spinner", {
                    attrs: { variant: "primary", label: "Loading..." },
                  }),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }